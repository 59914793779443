<template>
  <div class="tsform">
    <div class="explain-form">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="100px">
        <el-form-item label="建议对象名称">
          <el-input v-model="ruleForm.dymc" :disabled="true" placeholder="建议对象名称"></el-input>
        </el-form-item>

        <el-form-item label="用户名">
          <el-input v-model="ruleForm.yhmc" :disabled="true" placeholder="匿名用户"></el-input>
        </el-form-item>
        <el-form-item class="short-inpt" label="身份证号" prop="sfz">
          <el-input v-model="ruleForm.sfz" :disabled="ruleForm.disabled" placeholder=""></el-input>
        </el-form-item>

        <el-form-item class="short-inpt" label="姓名" prop="xm">
          <el-input v-model="ruleForm.xm" :disabled="ruleForm.disabled" placeholder=""></el-input>
        </el-form-item>

        <el-form-item class="short-inpt" label="手机号" prop="sjh">
          <el-input v-model="ruleForm.sjh" :disabled="ruleForm.disabled" placeholder=""></el-input>
        </el-form-item>

        <el-form-item v-if="false" class="short-inpt min-input" label="验证码">
          <el-input v-model="ruleForm.yznum" :disabled="ruleForm.disabled" placeholder="">
            <template slot="append">获取验证码</template>
          </el-input>
        </el-form-item>

        <el-form-item class="mar-bot" label="标题：" prop="wtbt">
          <el-input v-model="ruleForm.wtbt" :disabled="ruleForm.disabled" placeholder=""></el-input>
        </el-form-item>
        <el-form-item class="mar-bot" label="建议内容" prop="wtms">
          <el-input v-model="ruleForm.wtms" :disabled="ruleForm.disabled" type="textarea"></el-input>
        </el-form-item>
        <el-form-item v-if="!ruleForm.disabled" label="附件：">
          <div class="upload-file">
            <el-upload :action="uploadAction" :before-upload="beforeAvatarUpload" :disabled="ruleForm.disabled"
                       :headers="headers"
                       :on-remove="handleRemove" :on-success="handlePictureCardPreview" list-type="picture-card">
              <i class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip" style="color: red">只能上传jpg,png,文件，且不超过2MB</div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item v-if="!ruleForm.disabled" class="mar-bot">
          <el-checkbox v-model="checked" :disabled="ruleForm.disabled"></el-checkbox>
          <span class="sm-btn" @click="tpis=true">我已阅读并同意<a>《用户说明》</a></span>
        </el-form-item>
        <el-form-item v-if="ruleForm.disabled&&ruleForm.fj!=null&&ruleForm.fj!==''" class="mar-bot" label="附件：">
          <img :src="ruleForm.fj" style="width: 480px;height: 240px">
        </el-form-item>
        <el-form-item v-if="ruleForm.disabled" class="mar-bot" label="回复：">
          <el-input v-model="ruleForm.hf" :disabled="ruleForm.disabled" type="textarea"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <div v-if="!ruleForm.disabled" slot="footer" class="dialog-footer formd-foot" style="text-align: center">

      <el-button class="qued" type="primary" @click="saveRule">提交</el-button>
      <el-button class="cancel" @click="cancelRule">取消</el-button>
    </div>
    <el-dialog :visible.sync="tpis" append-to-body center class="sm-dialog" custom-class="sugg-dialog" top="10vh"
               width="720px">
      <div class="explain">
        <div class="title">用户说明</div>
        <p>为确保您的留言尽快得到回复，请您仔细阅读以下说明</p>
        <p>一、*为必填项，所有*项填写完成才能成功提交。</p>
        <p>
          二、留言须知：
          1.请您自觉遵守国家法律法规和国家机密；
          2.“留言”版块只用于与社会公众之间的信息交流，请勿包括与公示单位工作无关的任何内容，请勿包括任何投诉举报相关的内容；
          3.请您对留言的真实性、客观性负责，不发表虚假、敏感性、侮辱性、恐吓威胁、诽谤他人或单位的内容和言论，否则您将承担由此引发的一切法律责任；
          4.请勿对同一留言恶意连续、重复提交；
          5.请您填写真实的联系方式，方便与您联系，我们将依法保护您的个人隐私；
          6.请勿发表任何形式的广告等内容。</p>
        <p>如果您接受上述条款，请继续，我们将尽快回复您的留言，回复内容可在“个人中心”查看。</p>
      </div>
      <span slot="footer" class="dialog-footer">
				<button class="sm-btnn" type="primary" @click="tpis = false">同意</button>
			</span>
    </el-dialog>
    <el-dialog
        :visible.sync="successDialog"
        append-to-body
        center
        top="25vh"
        width="360px">
      <div class="sucessTs">
        <div class="pic-box">
          <img src="../../assets/images/tsjy/pic-sucess.png"/>
        </div>
        <a class="cg-text">提交成功!</a>
        <a class="gx-text">感谢您的宝贵意见！</a>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button style="width: 190px;height: 40px;padding: 0px 0px;" type="primary"
                       @click="successDialog = false">确 定</el-button>
          </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :modal="true" :show-close="false"
               :visible.sync="loginDialog" append-to-body>
      <login-card v-on:closeLogDialog="closeLogDialog"></login-card>
    </el-dialog>

  </div>
</template>
<script>
import urlConfig from "../../utils/urlConfig";
import LoginCard from "../../views/login/loginCard";
import storage from "../../utils/storage";
import {saveComplaintAndAdvice} from "../../api/personService";

export default {
  name: "tsjy",
  components: {
    LoginCard
  },
  data() {
    var validatePhoneNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
          callback(new Error("手机号码有误，请重填"));
        }
        callback();
      }
    };
    var validateIDCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号码'));
      } else {
        // 加权因子
        var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验码
        var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

        var code = value + "";
        var last = value[17];//最后一位

        var seventeen = code.substring(0, 17);

        // ISO 7064:1983.MOD 11-2
        // 判断最后一位校验码是否正确
        var arr = seventeen.split("");
        var len = arr.length;
        var num = 0;
        for (var i = 0; i < len; i++) {
          num = num + arr[i] * weight_factor[i];
        }

        // 获取余数
        var resisue = num % 11;
        var last_no = check_code[resisue];

        // 格式的正则
        // 正则思路
        /*
        第一位不可能是0
        第二位到第六位可以是0-9
        第七位到第十位是年份，所以七八位为19或者20
        十一位和十二位是月份，这两位是01-12之间的数值
        十三位和十四位是日期，是从01-31之间的数值
        十五，十六，十七都是数字0-9
        十八位可能是数字0-9，也可能是X
        */
        var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

        // 判断格式是否正确
        var format = idcard_patter.test(value);

        // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
        // return last === last_no && format ? true : false;
        if (last === last_no && format) {
          callback();
        } else {
          callback(new Error('身份证号码有误'));
        }

      }
    };
    return {
      uploadAction: urlConfig.upLoadUrl + '/graph/saveGraphImage?twlx=tsyj',
      loginDialog: false,
      successDialog: false,
      ruleForm: {
        sjh: '',
        yhmc: '',
        yznum: '',
        sfz: '',
        sfnm: '',
        xm: '',
        wtlx: '2',
        gzdw: '',
        sfgk: '',
        wtbt: '',
        wtms: '',
        gldyid: '',
        fj: '',
        dymc: "",
        yhid: '',
        disabled: false,
        hf: ''
      },
      fjList: [],
      dialogVisible: false,
      checked: false,
      tpis: false,
      rules: {
        sjh: [{
          validator: validatePhoneNumber,
          trigger: 'change'
        }],
        yznum: [{
          required: true,
          message: '请输入',
          trigger: 'change'
        }],
        sfz: [{
          validator: validateIDCard,
          trigger: 'change'
        }],
        sfgk: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        xm: [{
          required: true,
          message: '请输入',
          trigger: 'change'
        }],
        wtbt: [{
          required: true,
          message: '请输入问题',
          trigger: 'blur'
        }],
        wtms: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }]
      },
      headers: {
        'X-Gisq-Token': ''
      },
    }
  },
  mounted() {
    const user = storage.get("user")
    this.ruleForm.gldyid = this.$route.query.dyId
    this.ruleForm.dymc = this.$route.query.dymc
    if (this.ruleForm.dymc == undefined || this.ruleForm.dymc == null) {
      this.ruleForm.dymc = '平台'
    }
    this.ruleForm.yhmc = decodeURIComponent(user.user_nickname)
    this.ruleForm.sjh = user.user_tel
    this.headers['X-Gisq-Token'] = "Bearer " + user.access_token
  },
  methods: {
    closeLogDialog() {
      this.loginDialog = false;
    },

    //上传附件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(res, file) {
      this.fjList.push(res.finalUrl)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (this.fjList.length > 4) {
        this.$message.error('上传图片数量超过5张!');
        return false
      }
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG/GIF 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getTs() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          if (!this.checked) {
            this.$message.info("请勾选 已阅读《提交说明》")
          } else {
            this.ruleForm.fj = JSON.stringify(this.fjList);
            saveComplaintAndAdvice(this.ruleForm).then(res => {
              if (res.data > 0) {
                this.successDialog = true;
                this.$emit('closeVisible1', false)
              }
            })
          }
        }
      })
    },
    saveRule() {
      this.getTs()

    },
    cancelRule() {
      this.$emit('closeVisible1', false)
    },
    setFormData(glid) {
      this.ruleForm.gldyid = glid;
    },

    setTsData(ts) {
      this.ruleForm = ts;
    }
  }
}
</script>
<style>
.explain {
  border: 1px solid #CFDEE6;
  margin: 0px 15px 0px 15px;
  width: 640px;
  height: 480px;
  overflow: auto;
  padding: 0px 20px;
  box-sizing: border-box;
}

.explain .title {
  width: 100%;
  height: 26px;
  line-height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  text-align: center;
  padding: 19px 0px;
}

.explain p {
  line-height: 24px !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #000000;
  padding: 20px 0px;
}
</style>
<style lang="less">

.dialog-footer {
  .sm-btnn {
    width: 190px;
    height: 40px;
    background: #039BE5;
    border-radius: 4px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
  }
}

.sucessTs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pic-box, img {
    width: 82px;
    height: 80px;
  }

  a {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .cg-text {
    padding: 20px 0px 20px 0px;
    font-size: 24px;
    line-height: 28px;
  }

  .gx-text {
    font-size: 14px;
    line-height: 14px;
  }

}

.tsjycon {

  .explain-form {
    .explain-form-title {
      line-height: 24px;
      font-size: 18px;
      color: #303133;
      text-align: center;
      padding: 24px 0px;
    }

    .el-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .el-input-group {
        width: 240px;
      }

      .min-input {
        width: 50% !important;

        .el-input__inner {
          width: 130px !important;
        }
      }

      .short-inpt {
        width: 50% !important;

        .el-input__inner {
          width: 258px;
        }
      }

      .el-form-item {
        width: 100%;
        margin-bottom: 20px;
      }

      .mar-bot {
        margin-bottom: 20px;

        .sm-btn {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin-left: 10px;

          a {
            color: rgba(3, 155, 229, 1);
          }
        }
      }

      .el-form-item__content {


        .el-radio-group {
          margin-left: 15px;
        }
      }


    }

    .el-input__inner,
    .el-textarea__inner {
      /*border: 1px solid #ccc;*/
      /*background: #f6f6f6;*/
    }

    .el-textarea__inner {
      height: 100px;
    }
  }

  .formd-foot {
    .el-button {
      width: 180px;
      height: 40px;
      border-radius: 4px;
      padding: 0px 0px;
    }

    .cancel {
      background: #FFFFFF;
      border: 1px solid #0099E6;
      border-radius: 4px;
      color: #0099E6;
    }
  }

  .upload-file {
    .upload-file-title {
      line-height: 24px;
      font-size: 18px;
      color: #303133;
      text-align: center;
      padding: 24px 0px;
    }

    .el-upload-list--picture-card .el-upload-list__item {
      width: 64px;
      height: 64px;
    }

    .el-upload--picture-card {
      width: 64px;
      height: 64px;
    }

    .el-icon-plus:before {
      position: relative;
      top: -35px;
    }
  }


}
</style>
