<template>
  <div class="tsjycon center">
    <bread-crumb></bread-crumb>
    <div class="tab-box">
      <el-row :gutter="40">
        <el-col :span="12">
          <div class="grid-content">
            <div class="card">
              <img src="../../assets/images/tsjy/img_ly.png"/>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-right">
            <span class="gzlyText">[ 公众留言 ]</span>
			<p>
				如果你有什么好的建议，或有好的想法来与大家分享，都可以在此留言。
			</p>
			<button class="wylyBtn" @click="loginState">我要留言</button>
			<!-- <div class="btn" @click="loginState">
			  我要投诉
			</div> -->
            <!-- <div class="btn" @click="loginState1">
              我要建议
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row class="menu-title">
      <el-col class="title">
        <span>{{ currentMenu }}</span>
      </el-col>
    </el-row>
    <div class="table-box">
      <el-table
          :data="tableData"
          :v-loading="loading"
          style="width: 100%">
        <el-table-column
            label="序号"
            type="index"
            width="72">
        </el-table-column>
        <el-table-column
            label="留言人"
            prop="yhmc"
            width="200">
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="sjh"
            width="200">
        </el-table-column>
        <el-table-column
            label="标题"
            prop="wtbt">
          <template slot-scope="scope">
            <div style="cursor: pointer;" @click="openTsjy(scope.row)">
              {{ scope.row.wtbt }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="时间"
            prop="gxsj"
            width="240">
        </el-table-column>
      </el-table>
      <el-pagination
          :total="tsjyParam.total"
          layout="prev, pager, next"
          style="text-align: center;"
          @current-change="currentChange"
          @next-click="nextClick"
          @prev-click="prevClick">
      </el-pagination>
    </div>
    <el-dialog :destroy-on-close="true" :close-on-click-modal="false" :visible.sync="centerDialogVisible" title="我要留言" :top="'50'">
      <ts-form @closeVisible="closeDialog"></ts-form>
    </el-dialog>
<!--    <el-dialog :destroy-on-close="true" :close-on-click-modal="false" :visible.sync="jyDialogVisible" title="我要建议" :top="'50'">-->
<!--      <jy-form @closeVisible1="closeDialog1"></jy-form>-->
<!--    </el-dialog>-->
    <el-dialog
        :destroy-on-close="true"
        :visible.sync="tsdialog"
        title="详情"
        width="800px">
      <el-carousel ref="carousel" :autoplay="false" :initial-index="carouselIndex" arrow="always"
                   @change="carouselChange">
        <el-carousel-item v-for="(item, index) of tableData" :key="index">
          <div class="tscard tscard-1">
            <div class="title">
              <a class="title-con">{{ item.wtbt }}</a>
              <a class="time">{{ item.gxsj }}</a>
            </div>
            <div class="ts-con">
              <p>
                {{ item.wtms }}
              </p>
            </div>
          </div>
          <div class="tscard tscard-2" v-if="item.hf !== '' && item.hf !== null && item.hf !== undefined">
            <div class="title">
              <a class="title-con">{{ decodeURIComponent(item.hfbm) }}</a>
              <a class="time">{{ item.hfsj }}</a>
            </div>
            <div class="ts-con">
              <p>
                {{ item.hf }}
              </p>
            </div>
          </div>
          <div class="see-num">
            <a>浏览量：{{ item.seeNum }}</a>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>

  </div>
</template>
<script>
import breadCrumb from '@/components/common/breadCrumb';
import tsForm from '@/components/tsjyform/tsForm';
import jyForm from '@/components/tsjyform/jyForm';
import {tsjyLoginState} from "../../api/personService.js";
import {addTsjySeeNum, getHomeTsjy} from "../../api/personService";

export default {
  components: {
    breadCrumb, tsForm, jyForm
  },
  data() {
    return {
      tsjyParam: {
        start: 0,
        size: 10,
        total: 0,
        prop: "gxsj",
        order: "desc"
      },
      loading: false,
      tsdialog: false,
      currentMenu: "公众留言公开",
      tableData: [],
      centerDialogVisible: false, // 投诉对话框: false,
      jyDialogVisible: false,
      tsData: [],
      carouselIndex: 0,
    }
  },
  mounted() {
    this.tsjyParam.gldyid = this.$route.query.dyId
    this.getTsyjList(this.tsjyParam);
  },
  methods: {
    //关闭弹窗
    closeDialog(data) {
      this.centerDialogVisible = data
      this.getTsyjList(this.tsjyParam);
    },
    closeDialog1(data) {
      this.jyDialogVisible = data
      this.getTsyjList(this.tsjyParam);
    },
    loginState() {
      tsjyLoginState().then(res => {
          if (res.code === '0') {
              this.centerDialogVisible = true;
          }
      })
    },


    /**
     * 投诉建议数据获取
     * @param tsjyParam
     */
    getTsyjList(tsjyParam) {
      this.loading = true;
      getHomeTsjy(tsjyParam).then(res => {
              if (res.code === '0') {
                  this.tableData = res.data;
                  this.tsjyParam.total = res.total;
                  this.loading = false;
              }
      })
    },
    /**
     * 页码变化监听
     * @param val
     */
    currentChange(val) {
      this.tsjyParam.start = val;
      this.getTsyjList(this.tsjyParam);
    },
    /**
     * 下一页
     */
    nextClick(val) {
      this.tsjyParam.start = val;
      this.getTsyjList(this.tsjyParam);
    },
    /**
     * 上一页
     * @param val
     */
    prevClick(val) {
      this.tsjyParam.start = val;
      this.getTsyjList(this.tsjyParam);
    },
    /**
     * 投诉意见详情
     * @param tsyj
     */
    openTsjy(tsyj) {
      // addTsjySeeNum(tsyj.id).then(res => {
      //   console.log(res)
      // })
      this.tsdialog = true;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id === tsyj.id) {

          if (this.$refs.carousel !== undefined) {
            this.$refs.carousel.setActiveItem(i)
          } else {
            this.carouselIndex = i;
          }

          break
        }
      }
    },
    carouselChange(index) {
      console.log(this.tableData[index].id)
      addTsjySeeNum(this.tableData[index].id).then(res => {
        console.log(res)
      })
    }
  }
}
</script>
<style lang="less">
.tsjycon {
  .tab-box {
    padding: 80px 0px 70px 0px;

    .grid-content {
      display: flex;
      align-items: center;
      flex-direction: row;

      .card {
        width: 600px;
        height: 280px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
		  margin-left:60px;
          width:540px;
          height: 280px;
        }
      }

      .btn {
        width: 280px;
        height: 120px;
        background: #E5F7FF;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #039BE5;
        line-height: 120px;
        text-align: center;
        cursor: pointer;
      }
    }

    .grid-right {
      // justify-content: flex-end;
	  padding: 38px 0px 61px 60px;
	  box-sizing: border-box;
	  .gzlyText{
		  display: block;
		  font-size: 24px;
		  font-family: Microsoft YaHei;
		  font-weight: bold;
		  color: #333333;
		  line-height:24px;
		  margin-bottom:18px;
	  }
	  p{
		  display: block;
		  width: 294px;
		  font-size: 14px;
		  font-family: Microsoft YaHei;
		  font-weight: 400;
		  color: #666666;
		  line-height: 24px;
		  margin-bottom: 60px;
	  }
	  .wylyBtn{
		  width: 160px;
		  height: 40px;
		  background: #FFFFFF;
		  border: 1px solid #039BE5;
		  border-radius: 4px;
		  font-size: 16px;
		  font-family: Microsoft YaHei;
		  font-weight: 400;
		  color: #039BE5;
		  line-height: 40px;
		  text-align: center;
		  cursor: pointer;
	  }
    }
  }

  .menu-title {
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000;
      line-height: 40px;
      margin-bottom: 20px;
      border-bottom: 2px solid #039BE5;
    }
  }

  .table-box {
    padding-bottom: 60px;

    .el-table th {
      background: #F0F5F7;
    }

    .el-table .cell {
      text-align: center;
      font-size: 16px;
    }
  }

  .el-dialog {
    width: 800px;
    border-radius: 4px;

    .el-dialog__header {
      padding: 15px 20px 15px 30px;
      background: #EBF2F5;
      border-radius: 4px 4px 0px 0px;

      .el-dialog__title {
        line-height: 26px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .el-carousel__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tscard {
      width: 680px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(207, 222, 230, 0.6);
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 20px;
      box-sizing: border-box;
      .title {
        display: flex;
        flex-direction: row;
        margin-bottom: 18px;

        .title-con {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 22px;
          padding-left: 31px;
        }

        .time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          margin-left: auto;
        }

      }

      .ts-con {
        padding-left: 32px;
        box-sizing: border-box;

        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          text-align: justify;
        }

      }

    }

    .see-num {
      width: 680px;
      display: flex;
      flex-direction: row;

      a {
        margin-left: auto;
        display: inline-block;
        width: 100px;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #CCCCCC;
        line-height: 26px;
        background: url(../../assets/images/tsjy/icon-see.png) no-repeat left center;
      }

    }

    .tscard-1 {
      background: url(../../assets/images/tsjy/img-bg1.png) no-repeat left top, rgba(255, 254, 242, 1);

      .title {
        .title-con {
          background: url(../../assets/images/tsjy/icon-tousu.png) no-repeat left center;
          background-size: 20px 20px;
        }
      }
    }

    .tscard-2 {
      background: url(../../assets/images/tsjy/img-bg2.png) no-repeat left top, rgba(255, 255, 255, 1);

      .title {
        .title-con {
          background: url(../../assets/images/tsjy/icon-huifu.png) no-repeat left center;
          background-size: 20px 20px;
        }
      }
    }

  }

  .el-carousel__arrow {
    width: 28px;
    height: 28px;
    border-radius: 0px;

    i {
      display: none;
    }
  }

  /* 隐藏掉走马灯效果底部指示器 */

  .el-carousel__indicators--horizontal {
    display: none;
  }

  .el-carousel__arrow--right {
    right: 0px !important;
    background: url(../../assets/images/tsjy/btn-right.png) no-repeat center center;
  }

  .el-carousel__arrow--left {
    left: 0px !important;
    background: url(../../assets/images/tsjy/btn-left.png) no-repeat center center;
  }

  .el-carousel__container {
    min-height: 350px !important;
  }
}

</style>

